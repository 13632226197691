import { motion } from 'framer-motion'
import React from 'react'
import Button from 'react-bootstrap/Button';
import { SectionHeader } from '../universal/SectionHeader'
import { sections } from '../universal/sectionInfo'
import "./contact.css"

export const Contact = ({startAnimation}) => {

  return (
    <section id='contact' className='contact'>
      <motion.div
        className='mediumIcon'
        initial={{opacity:0, y:125}}
        animate={startAnimation ? {opacity:1, y:0}:{}}
        transition={startAnimation ? {duration:1}:{}}
      />
      <SectionHeader startAnimation={startAnimation} headerText={sections.contact.header} subheaderText={sections.contact.subheader}/>
      <motion.p
        className="info-section"
        initial={{opacity:0, y:125}}
        animate={startAnimation ? {opacity:1, y:0}:{}}
        transition={startAnimation ? {duration:1, delay:.75}:{}}
      >
       {sections.contact.data}
      </motion.p>
      <motion.div
        initial={{opacity:0, y:125}}
        animate={startAnimation ? {opacity:1, y:0}:{}}
        transition={startAnimation ? {duration:1, delay:1}:{}}
      >
        <Button variant="dark" size="lg" onClick={() => {window.open('mailto:agarza07@calpoly.edu?subject=Reaching Out!')}}>Reach Out</Button>
      </motion.div>
      
      <p className='footer-text'>{"Designed \& Built by Amando Garza"}</p>
    </section>
  )
}

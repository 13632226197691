import React, { useState } from 'react'
import './about.css'
import { motion } from 'framer-motion';
import { SectionHeader } from '../universal/SectionHeader';
import { sections } from '../universal/sectionInfo';

export const About = ({ startAnimation }) => {

  return (
    <section id='about' className='about'>
      <motion.div
        className='easyIcon'
        initial={{opacity:0, y:100}}
        animate={startAnimation ? {opacity:1, y:0}:{}}
        transition={startAnimation ? {duration:.5}:{}}
      />
      <SectionHeader startAnimation={startAnimation} headerText={sections.about.header} subheaderText={sections.about.subheader}/>
      <motion.p
        className="info-section"
        initial={{opacity:0, y:100}}
        animate={startAnimation ? {opacity:1, y:0}:{}}
        transition={startAnimation ? {duration:.5, delay:.75}:{}}
      >
       {sections.about.data}
      </motion.p>
    </section>
  )
}


export const generateM = (start_x, start_y, height, width) => {
    //start - lower-left pt
    //height - height of m
    //width - width of m

    //output - [{end point at lower right}, {drawing data}]
    return [{x:start_x+width,y:start_y},[
        {x1:start_x, x2:start_x, y1:start_y,y2:start_y-height},
        {x1:start_x, x2:start_x+width/2, y1:start_y-height,y2:start_y},
        {x1:start_x+width/2, x2:start_x+width, y1:start_y,y2:start_y-height},
        {x1:start_x+width, x2:start_x+width, y1:start_y-height,y2:start_y},
    ]]
}

export const generateO = (start_x, start_y, radius, distance) => {
    //output - [{end point at lower right}, {drawing data}]
    return [{x:start_x + distance + radius*2,y:start_y},{
        cx:start_x + distance + radius,
        cy:start_y - radius,
        r:radius,
    }]
}

export const generateN = (start_x, start_y, height, width) => {
    //start - lower-left pt
    //height - height of m
    //width - width of m

    //output - [{end point at lower right}, {drawing data}]
    return [{x:start_x+width/2,y:start_y},[
        {x1:start_x, x2:start_x, y1:start_y,y2:start_y-height},
        {x1:start_x, x2:start_x+width/2, y1:start_y-height,y2:start_y},
        {x1:start_x+width/2, x2:start_x+width/2, y1:start_y,y2:start_y-height},
    ]]
}

export const generateD = (start_x, start_y, height, width) => {
    //start - lower-left pt
    //height - height of m
    //width - width of m

    //output - [{end point at lower right}, {drawing data}]
    return [{x:start_x+width/2,y:start_y},[
        {x1:start_x, x2:start_x, y1:start_y,y2:start_y-height},
        {x1:start_x, x2:start_x+width/2, y1:start_y-height,y2:start_y},
        {x1:start_x+width/2, x2:start_x+width/2, y1:start_y,y2:start_y-height},
    ]]
}


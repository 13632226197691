import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import ReactTypingEffect from 'react-typing-effect'
import './introduction.css'

export const Introduction = ({ onAnimationFinish, startAnimation}) => {
   
  return (
    <div className='introduction-container'>
      <div className='name-container'>
        <motion.h1
          className="name"
          initial={{opacity:0, y:100}}
          animate={startAnimation ? {opacity:1, y:0}:{}}
          transition={startAnimation ? {duration:1.5, delay:2}:{}}
        >
          Amando
        </motion.h1>
        <motion.h1
          className="last name"
          initial={{opacity:0, y:100}}
          animate={startAnimation ? {opacity:1, y:0}:{}}
          transition={startAnimation ? {duration:1.5, delay:2}:{}}
        >
          <ReactTypingEffect
            text={"Garza"}
          />
        </motion.h1>
      </div>
      <div className='title-container'>
        <motion.h2
          className="title"
          initial={{opacity:0, y:100}}
          animate={startAnimation ? {opacity:1, y:0}:{}}
          transition={startAnimation ? {duration:1.5, delay:2.5}:{}}
          onAnimationComplete={onAnimationFinish}
        >
          SOFTWARE E
        </motion.h2>
        <motion.h2
          className="white title"
          initial={{opacity:0, y:100}}
          animate={startAnimation ? {opacity:1, y:0}:{}}
          transition={startAnimation ? {duration:1.5, delay:2.5}:{}}
          onAnimationComplete={onAnimationFinish}
        >
          NGINEER
        </motion.h2>
      </div>
      {/* <motion.div
        className='inner-circle'
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration:4 }}
      /> */}
    </div>
  )
}

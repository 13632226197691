import { Container, Navbar, Nav } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import './navbar.css'
import { motion } from 'framer-motion'

export const NavBar = ({ startAnimation }) => {
    const [activeLink, setActiveLink] = useState('home')

    const navItemClicked = (sectionId) => {
        const yOffset = -10; 
        const element = document.getElementById(sectionId);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: 0, behavior: 'smooth'});

    }

    const expand = false

    return (
        <Navbar key={expand} expand={expand} className="mb-3">
            <Container fluid>
                <motion.div
                    initial={{y:-100, opacity:0}}
                    animate={startAnimation ? {y:0, opacity:1}:{}}
                    transition={{duration:1, delay:1.25}}
                >
                    <Navbar.Brand href="#">M | G</Navbar.Brand>
                </motion.div>
                <Nav className="justify-content-end flex-row">
                    <Nav.Item>
                        <motion.div
                            initial={{y:-100, opacity:0}}
                            animate={startAnimation ? {y:0, opacity:1}:{}}
                            transition={{duration:1, delay:1.5}}
                        >
                            <Nav.Link onClick={event => navItemClicked("about")} href="#about">About</Nav.Link>
                        </motion.div>
                    </Nav.Item>
                    <Nav.Item>
                        <motion.div
                            initial={{y:-100, opacity:0}}
                            animate={startAnimation ? {y:0, opacity:1}:{}}
                            transition={{duration:1, delay:1.75}}
                        >
                            <Nav.Link onClick={event => navItemClicked("experience")} href="#experience">Experience</Nav.Link>
                        </motion.div>
                    </Nav.Item>
                    {/* <Nav.Item>
                        <Nav.Link href="#skills">Skills</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                        <motion.div
                            initial={{y:-100, opacity:0}}
                            animate={startAnimation ? {y:0, opacity:1}:{}}
                            transition={{duration:1, delay:2}}
                        >
                            <Nav.Link onClick={event => navItemClicked("contact")} href="#contact">Contact</Nav.Link>
                        </motion.div>
                    </Nav.Item>
                </Nav>
            </Container>
        </Navbar>
    )
}

import React from 'react'

export const ExperienceSlope = ({ className, strokeDashArray, strokeDashOffset }) => {
  return (
    <div className={className}>
        {/* <svg width="827" height="654" viewBox="0 0 827 654" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="649" r="5" fill="#FF0000"/>
        <circle cx="822" cy="5" r="5" fill="#FF0000"/>
        <path d="M823 5C786.333 19.3333 724 52.6 768 71C823 94 677 114 768 161C859 208 693 233 651 233C573 233 408 288 420 405C426.333 464.333 352.6 596 7 648" stroke="#FF0000" strokeWidth={4} strokeDasharray={strokeDashArray} strokeDashoffset={strokeDashOffset}/>
      </svg> */}
      <svg width="798" height="645" viewBox="0 0 798 645" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="638" r="5" fill="black"/>
        <circle cx="793" cy="6" r="5" fill="black"/>
        <path d="M793 6C756.333 20.3333 694 52.6 738 71C793 94 688 109 732 141C786.33 180.513 651 170 628 210C585.744 283.488 392.207 261 378 364C370 422 368 624 5 639" stroke="black" strokeWidth={3} strokeDasharray={strokeDashArray} strokeDashoffset={strokeDashOffset}/>
        <path d="M793 6C756.333 20.3333 694 52.6 738 71C793 94 688 109 732 141C786.33 180.513 651 170 628 210C585.744 283.488 392.207 261 378 364C370 422 368 624 5 639" stroke="black" stroke-opacity="0.35" strokeWidth={12} strokeDasharray={strokeDashArray} strokeDashoffset={strokeDashOffset}/>
      </svg>
    </div>
  )
}

import React from 'react'
import { SectionHeader } from '../universal/SectionHeader'
import { motion } from 'framer-motion'
import "./experience.css"
import { sections } from '../universal/sectionInfo'
import Carousel from 'react-bootstrap/Carousel'

export const Experience = ({startAnimation}) => {

  return (
    <section id='experience' className='experience'>
      <div className='header-container'>
        <motion.div
          className='icon-container'
          initial={{opacity:0, y:125}}
          animate={startAnimation ? {opacity:1, y:0}:{}}
          transition={startAnimation ? {duration:.75}:{}}
        >
          <motion.svg width="28" height="45" viewBox="0 0 28 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <motion.path d="M14 0L28 22.5L14 45L0 22.5L14 0Z" fill="black"/>
          </motion.svg>
          <motion.svg width="28" height="45" viewBox="0 0 28 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <motion.path d="M14 0L28 22.5L14 45L0 22.5L14 0Z" fill="black"/>
          </motion.svg>
        </motion.div>
        <SectionHeader startAnimation={startAnimation} headerText={sections.experience.header} subheaderText={sections.experience.subheader}/>
      </div>
      <motion.div 
        className='carousel-container'
        initial={{opacity:0, y:125}}
        animate={startAnimation ? {opacity:1, y:0}:{}}
        transition={startAnimation ? {duration:.5, delay:.75}:{}}  
      >
        <Carousel variant="dark">
            { sections.experience.data.map((job) => {
              return (
                  <Carousel.Item>
                    <Jobs job={job} />
                  </Carousel.Item>
                )
              })
            }
        </Carousel>
      </motion.div>
    </section>
  )
}

const Jobs = ({ job }) => {
  return (
    <div className='job-container'>
      <div className='title-container'>
        <div>
          <h1 className='position'>{job.position}</h1>
          <h2 className='companyName'>@{job.company}</h2>
        </div>
        <h2 className='date'>{job.date}</h2>
      </div>
      <div className='bulletpoints-container'>
        <ul>
          { job.accomplishments.map((bulletPoint) => {
            return (
                <li className='bulletPointItem'>{bulletPoint}</li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}

{/* <div className='info-container'>
<div className='title-container'>
  <div>
    <h1 className='position'>{sections.experience.data[0].position}</h1>
    <h2 className='companyName'>@{sections.experience.data[0].company}</h2>
  </div>
  <h2 className='date'>Feb - Sept 2021</h2>
</div>
<ul>
  { sections.experience.data[0].accomplishments.map((bulletPoint) => {
    return (
        <li className='bulletPointText'>{bulletPoint}</li>
      )
    })
  }
</ul>
</div> */}


import { motion } from 'framer-motion'
import React from 'react'
import './sectionheader.css'

export const SectionHeader = ({ startAnimation, headerText, subheaderText }) => {
  return (
    <>
        <motion.h1
            className="header-section"
            initial={{opacity:0, y:100}}
            animate={startAnimation ? {opacity:1, y:0}:{}}
            transition={startAnimation ? {duration:.5, delay:.2}:{}}
        >
            {headerText}
        </motion.h1>
        <motion.h2
            className="subheader-section"
            initial={{opacity:0, y:100}}
            animate={startAnimation ? {opacity:1, y:0}:{}}
            transition={startAnimation ? {duration:.5, delay:.4}:{}}
        >
            {subheaderText}
        </motion.h2>
    </>
  )
}

import { motion } from 'framer-motion'
import React, { useState } from 'react'
import "./mountainside.css" 
import { getSlopeLength } from './slopeFunctions'
import { AboutSlope } from './SlopeSVGs/AboutSlope'
import { ContactSlope } from './SlopeSVGs/ContactSlope'
import { ExperienceSlope } from './SlopeSVGs/ExperienceSlope'

export const MountainSide = ({startAnimation}) => {
  const windowHeight = window.innerHeight

  const [showCopyright, setShowCopyright] = useState(false)
  const [showAboutSlope, setShowAboutSlope] = useState(false)
  const [aboutOffset, setAboutOffset] = useState(1600)
  const [showExperienceSlope, setShowExperienceSlope] = useState(false)
  const [experienceOffset, setExperienceOffset] = useState(1600)
  const [showContactSlope, setShowContactSlope] = useState(false)
  const [contactOffset, setContactOffset] = useState(1600)

  const slopeSections = {
    // "Section Name":[start slope, end slope]
    about:[windowHeight/3, (7*windowHeight)/4],
    experience:[(6*windowHeight)/4, (10*windowHeight)/4],
    contact:[(5*windowHeight)/2, (14*windowHeight)/4],
    // "skills":[(6*windowHeight)/4, 3*windowHeight],
    // "projects":[windowHeight/4, windowHeight*3],
  }
  
  window.addEventListener('scroll', () => {
    var currentScrollLength = window.scrollY

    //About Slope
    if (currentScrollLength > slopeSections.about[0] && currentScrollLength < slopeSections.about[1]){
      setShowAboutSlope(true)
      setAboutOffset(getSlopeLength(slopeSections.about[0], slopeSections.about[1], currentScrollLength))
    } else {
      setShowAboutSlope(false)
    }

    //Experience Slope
    if (currentScrollLength > slopeSections.experience[0] && currentScrollLength < slopeSections.experience[1]){
      setShowExperienceSlope(true)
      setExperienceOffset(getSlopeLength(slopeSections.experience[0], slopeSections.experience[1], currentScrollLength))
    } else {
      setShowExperienceSlope(false)
    }

    //Contact Slope
    if (currentScrollLength > slopeSections.contact[0] && currentScrollLength < slopeSections.contact[1]){
      setShowContactSlope(true)
      setContactOffset(getSlopeLength(slopeSections.contact[0], slopeSections.contact[1], currentScrollLength))
    } else {
      setShowContactSlope(false)
    }
  })

  return (
    <motion.div
        className='mountain-container'
        initial={{scaleX:1.7, x:"-20vw"}}
        animate={startAnimation ? {scaleX:1, x:0}:{}}
        transition={startAnimation ? {duration:3, ease:"easeInOut"}:{}}
        onAnimationComplete={() => {setShowCopyright(true)}}
    >
      <motion.p animate={ showCopyright ? {opacity:1}:{}} initial={{opacity:0}} transition={{duration:1.5}} className='copyright'>Copyright 2022. All Rights Reserved</motion.p>
      <motion.div
        className='about-slope-container'
        initial={{opacity:0}}
        animate={showAboutSlope ? {opacity:1}:{}}  
      >
        <AboutSlope className='slope' strokeDashOffset={aboutOffset} strokeDashArray={1600}/>
      </motion.div>
      <motion.div
        className='experience-slope-container'
        initial={{opacity:0}}
        animate={showExperienceSlope ? {opacity:1}:{}}  
      >
        <ExperienceSlope className='slope' strokeDashOffset={experienceOffset} strokeDashArray={1600}/>
      </motion.div>
      <motion.div
        className='contact-slope-container'
        initial={{opacity:0}}
        animate={showContactSlope ? {opacity:1}:{}}  
      >
        <ContactSlope className='slope' strokeDashOffset={contactOffset} strokeDashArray={1600}/>
      </motion.div>
    </motion.div>
  )
}

export const sections = {
    about:{
        header:"About",
        subheader:"Alley",
        data:"Born and raised in what is considered the happiest place in the world, San Luis Obispo, i’m a kid fueled by curiousity, hardwork, and the relentless pursuit of happpiness. Over the past 5 years i’ve been given the opportunity to work and study alongside highly intelligent individuals at California Polytechnic State University - SLO where I recieved a bachelors in Agricultural Engineering. As with the theme of my portfolio, the snow is a place I absolutely cannot live without and as such, two things I love to do when I am away from the desk include snowboarding and calisthenics."
    },
    experience:{
        header:"Experience",
        subheader:"Express",
        data:[
            {
                company:"Sun Pacific Farming Corp.",
                position:"Water Data Management Specialist",
                date:"Feb - Sept 2021",
                accomplishments:[
                    "Utilized the ArcGIS ArcPy python library and the Conda environment to automate geospatial mapping workflows",
                    "Programmed numerous SQL queries for water data analysis as well as ETL processes utilizing excel pivot tables",
                    "Scripted numerous python applications that performed ETL processes with geoJSON and JSON data from REST APIs"
                ]
            },
            {
                company:"Mindbody Inc.",
                position:"Data Management Specialist",
                date:"Feb - Sept 2021",
                accomplishments:[
                    "Lead efforts in drastically decreasing the amount of time associated with quantifying, analyzing, and assessing the risk of adoptable 3rd party applications",
                    "Integrated Microsoft forms, in mixture with power automate, formalizing an automated process for verifying new 3rd party applications"
                ]
            },
            {
                company:"Cannon Corp.",
                position:"Electrical Engineering Intern",
                date:"Feb - Sept 2021",
                accomplishments:[
                    "Drive cross-functional engineering efforts to assemble, integrate, and validate numerous industrial PLC panels from schematic to runtime for automation of commercial and government (Vandenberg Air Force Base) processes",
                ]
            },
        ]
    },
    contact:{
        header:"Contact",
        subheader:"Coaster",
        data:"I’m always excited and open to new opportunities so feel feel to reach out:)"
    }
}
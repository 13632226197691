import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react'
import './loading.css'
import { generateM, generateN, generateO } from './helperFunctions';

const draw = (pLength) => {
  return ({
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i) => {
          const delay = 1 + i * 0.5;
          return {
            pathLength: pLength,
            opacity: 1,
            transition: {
              pathLength: { delay, type: "spring", duration: 1.25, bounce: 0 },
              opacity: { delay, duration: 0.01 }
            }
          };
        }
    })
};

export const Loading = ({onFinishLoading}) => {
  const [loaded, setLoaded] = useState(0)
  
  //container settings
  const containerHeight = 600
  const containerWidth = 500

  const lineLetterThickness = 10
  const letterHeight = 60
  const letterWidth = 90
  const distanceBtwLetters = 25
  const startLettersPt = {x:lineLetterThickness,y:lineLetterThickness/2+containerHeight/2+letterHeight/2}

  const M = generateM(startLettersPt.x,startLettersPt.y,letterHeight,letterWidth)
  const O1 = generateO(M[0].x, M[0].y, letterHeight/2, distanceBtwLetters)
  const N = generateN(O1[0].x + distanceBtwLetters, O1[0].y, letterHeight, letterWidth)
  const D = generateO(N[0].x, N[0].y, letterHeight/2, distanceBtwLetters)
  const O2 = generateO(D[0].x, D[0].y, letterHeight/2, distanceBtwLetters)

  const screenDissapeared = () => {
    if (loaded < 0){
      onFinishLoading()
    }
  }
  
  return (
    <motion.div
      className='loading-container'
      animate={{y:loaded}}
      onAnimationComplete={screenDissapeared}
      transition={{ ease:"easeInOut", duration: 1 }}
    >
        <motion.svg
            width={containerWidth}
            height={containerHeight}
            initial="hidden"
            animate="visible"
            className={'svg-container'}
        >
            {   M[1].map((item, index, key) => {
                    return (
                        <motion.line
                          className={"letter-m"}
                          x1={item.x1}
                          x2={item.x2}
                          y1={item.y1}
                          y2={item.y2}
                          stroke="black"
                          variants={draw(1)}
                          custom={index}
                        />
                    )
                })
            }
            <motion.circle
                className={"letter-o"}
                cx={O1[1].cx}
                cy={O1[1].cy}
                r={O1[1].r}
                stroke="black"
                variants={draw(1)}
                custom={1}
            />
            {   N[1].map((item, index, key) => {
                return (
                  <motion.line
                      className={"letter-n"}
                      x1={item.x1}
                      x2={item.x2}
                      y1={item.y1}
                      y2={item.y2}
                      stroke="black"
                      variants={draw(1)}
                      custom={2}
                  />
                )
              })
            }
            <motion.circle
              className={"letter-o"}
              cx={D[1].cx}
              cy={D[1].cy}
              r={D[1].r}
              stroke="black"
              variants={draw(.5)}
              custom={1}
            />
          <motion.line
              className={"letter-o"}
              x1={D[1].cx - D[1].r}
              x2={D[1].cx + D[1].r}
              y1={D[1].cy}
              y2={D[1].cy}
              stroke="black"
              variants={draw(1)}
              onAnimationComplete={() => {setLoaded(-window.innerHeight)}}
              custom={3}
            />
            <motion.circle
              className={"letter-o"}
              cx={O2[1].cx}
              cy={O2[1].cy}
              r={O2[1].r}
              stroke="black"
              variants={draw(1)}
              custom={1}
            />
        </motion.svg>
    </motion.div>
  )
}



// />
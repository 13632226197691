import React from 'react'

export const ContactSlope = ({ className, strokeDashArray, strokeDashOffset }) => {
  return (
    <div className={className} >
      <svg width="801" height="488" viewBox="0 0 801 488" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="477" r="5" fill="#0072B1"/>
        <circle cx="796" cy="6" r="5" fill="#0072B1"/>
        <path d="M796 6C771 16 755 20.4 707 26C647 33 536.667 201.333 513 251C418 348 267.4 508.4 5 478" stroke="#0072B1" strokeWidth={3} strokeDasharray={strokeDashArray} strokeDashoffset={strokeDashOffset}/>
        <path d="M796 6C771 16 755 20.4 707 26C647 33 536.667 201.333 513 251C418 348 267.4 508.4 5 478" stroke="#0072B1" stroke-opacity="0.35" strokeWidth={12} strokeDasharray={strokeDashArray} strokeDashoffset={strokeDashOffset}/>
      </svg>
    </div>
  )
}

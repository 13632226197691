import React from 'react'

export const AboutSlope = ({ className, strokeDashOffset, strokeDashArray }) => {
  return (
    <div className={className}>
      {/* <svg width="561" height="493" viewBox="0 0 561 493" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="488" r="5" fill="#FF0000"/>
        <circle cx="556" cy="5" r="5" fill="#FF0000"/>
        <path d="M557 5C484.334 30.3333 362 77 362 99C362 143.046 417 183 356 220C295 257 248 233 250 296C251.6 346.4 244 360 172 372C148 371 105 379.2 125 420C145 460.8 54.0001 482.333 6 488" stroke="#FF0000" strokeWidth={4} strokeDasharray={strokeDashArray} strokeDashoffset={strokeDashOffset}/>
      </svg> */}
      <svg width="521" height="473" viewBox="0 0 521 473" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio='xMidYMax meet'>
        <circle cx="5" cy="467" r="5" fill="#29CD00"/>
        <circle cx="516" cy="6" r="5" fill="#29CD00"/>
        <path d="M516 6C443.333 31.3333 327 65 308 95C287.696 127.059 350.496 173.867 281 190C225 203 202.724 208.175 230 265C242 290 234 337 173 333C147 323 99 343.2 119 384C139 424.8 54.0001 461.333 6 467" stroke="#29CD00" strokeWidth={3} strokeDasharray={strokeDashArray} strokeDashoffset={strokeDashOffset}/>
        <path d="M516 6C443.333 31.3333 327 65 308 95C287.696 127.059 350.496 173.867 281 190C225 203 202.724 208.175 230 265C242 290 234 337 173 333C147 323 99 343.2 119 384C139 424.8 54.0001 461.333 6 467" stroke="#29CD00" stroke-opacity="0.35" strokeWidth={12} strokeDasharray={strokeDashArray} strokeDashoffset={strokeDashOffset}/>
      </svg>
    </div>
  )
}

import React, { useState } from 'react'
import { NavBar } from './components/navbar/NavBar'
import { About } from './components/about/About'
import { FooterBtn }  from './components/FooterBtn'
import { Socials } from './components/social/Socials'
import { Introduction } from './components/introduction/Introduction'
import { Experience } from './components/experience/Experience'
import { Loading } from './components/loading/Loading'
import { Contact } from './components/contact/Contact'
import { MountainSide } from './components/mountain/MountainSide'
import './app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { motion } from 'framer-motion'

const App = () => {
  const [loadingFinished, setLoadingFinished] = useState(false)
  const [introFinished, setIntroFinished] = useState(false)
  const [showAbout, setShowAbout] = useState(false)
  const [showExperience, setShowExperience] = useState(false)
  const [showContact, setShowContact] = useState(true)
  const [showScroll, setShowScroll] = useState(true)

  const scrolled = () => {
    var windowSize = window.innerHeight

    //Show About
    if (window.scrollY > windowSize/2) {
      setShowAbout(true)
    } else {
      setShowAbout(false)
    }

    //Show Experience
    if (window.scrollY > (6*windowSize)/4) {
      setShowExperience(true)
    } else {
      setShowExperience(false)
    }

    //Show Contact
    if (window.scrollY > (10*windowSize)/4) {
      setShowContact(true)
    } else {
      setShowContact(false)
    }
    setShowScroll(false)
  }

  window.addEventListener('scroll', scrolled)

  const ScrollBtn = () => {
    if (introFinished && showScroll){
      return (
        <motion.div
          className='scroll-container'
          initial={{opacity:0}}
          animate={ showScroll ? {opacity:1}:{opacity:0}}
          transition={{ delay:2 }}
        >
          <div className='scroll-icon' />
          <p className='scroll-text'>Scroll</p>
        </motion.div>
      )
    } else {
      return
    }
  }

  return (
    <div className='app'>
      <Loading onFinishLoading={() => {setLoadingFinished(true)}}/>
      <NavBar startAnimation={loadingFinished}/>
      <Introduction onAnimationFinish={() => {setIntroFinished(true)}} startAnimation={loadingFinished} />
      { introFinished && 
        <>
          <About startAnimation={showAbout} />
          <Experience startAnimation={showExperience} />
          <Contact startAnimation={showContact} />
        </>
      }
      {/* <Socials startAnimation={introFinished}/> */}
      <ScrollBtn />
      <MountainSide startAnimation={loadingFinished} />
    </div>
  )
}

export default App